.inquiry-input {
  display: flex;
  justify-content: center;
}

.inquiry-btn {
  width: 100%;
  background-color: #00b1bb;
  color: white;
  border: none;
  font-family: var(--h1-font-family);
}

.table-header-color th {
  color: #747474 !important;
}

.table-nowrap {
  white-space: nowrap;
  text-align: center;
}

.inquiry-margin {
  margin-bottom: 30vh;
}

@media (max-width: 575.98px) {
  .inquiry-input {
    flex-direction: column;
    margin: 0 8px 0 8px;
  }

  .inquiry-margin {
    margin-bottom: 20vh;
  }
}
