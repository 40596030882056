.detail-board-title {
  font-size: 32px;
}

.img-size-adjust img {
  max-width: 600px;
}

.img-size-adjust-frequent img {
  max-width: 400px;
}

p:has(img) {
  text-align: center;
}

@media (max-width: 767.98px) {
  .detail-board-title {
    font-size: 20px;
  }
  .detail-writer {
    font-size: 14px;
  }

  .img-size-adjust img {
    max-width: 400px;
  }

  .img-size-adjust-frequent img {
    max-width: 200px;
  }
}

@media (max-width: 575.98px) {
  .img-size-adjust img {
    max-width: 300px;
  }
  .img-size-adjust-frequent img {
    max-width: 200px;
  }
}
