.title-click:hover {
  cursor: pointer;
  text-decoration: underline;
}

.main-notice:hover {
  cursor: pointer;
  color: #36bdef;
  transition-duration: 0.5s;
}
