.notice-and-see-more {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  transition-duration: 0.5s;
}

.see-more:hover {
  color: #36bdef !important;
  transition-duration: 0.5s;
}

.individual-group-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
}

.individual {
  font-size: 48px;
}

.group {
  font-size: 48px;
}

.icon-circle1 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px black solid;
  text-align: center;
  transition-duration: 0.5s;
}

.icon-circle1:hover {
  cursor: pointer;
  background-color: #36bdef;
  border: 3px #36bdef solid;
  transition-duration: 0.5s;
}

.icon-circle1:hover .individual {
  color: #36bdef;
  transition-duration: 0.5s;
}

.icon-circle1:hover .icon-text1 {
  color: #36bdef;
  transition-duration: 0.5s;
}

.icon-circle1:hover .icon-fill1 {
  display: inline-block;
  color: black;
  background-color: transparent;
  transition-duration: 0.5s;
}

.icon-circle1:hover .icon-default1 {
  color: white;
  transition-duration: 0.5s;
}

.icon-circle2 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px black solid;
  text-align: center;
  transition-duration: 0.5s;
}

.icon-circle2:hover {
  cursor: pointer;
  background-color: #36bdef;
  border: 5px #36bdef solid;
  transition-duration: 0.5s;
}

.icon-circle2:hover .group {
  color: #36bdef;
  transition-duration: 0.5s;
}

.icon-circle2:hover .icon-text2 {
  color: #36bdef;
  transition-duration: 0.5s;
}

.icon-circle2:hover .icon-default2 {
  color: white;
  transition-duration: 0.5s;
}

.icon-text1 {
  margin-top: 15px;
}

.icon-text2 {
  margin-top: 15px;
}

@media (max-width: 767.98px) {
  .main-notice-container {
    height: 350px !important;
  }
  .sm-main-notice {
    margin-bottom: 15px;
  }
}
