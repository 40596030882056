.link-font-size {
  font-size: 36px;
}

.link-font-size:hover {
  color: #36bdef !important;
}

.picture-container-margin {
  margin-top: 10vh !important;
  margin-bottom: 50vh;
}

.picture-ready-font-size {
  font-size: 40px;
}

.picture-ready-icon-size {
  font-size: 70px;
}

.picture-ready-conter-margin {
  margin-top: 10vh !important;
  margin-bottom: 40vh;
}

@media (max-width: 575.98px) {
  .picture-container-margin {
    margin-top: 10vh !important;
    margin-bottom: 40vh;
  }
  .link-font-size {
    font-size: 18px;
  }
}
