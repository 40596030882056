@font-face {
  font-family: "NanumGothic";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/NanumGothic.ttf") format("truetype"),
    url("../fonts/NanumGothic.otf") format("opentype");
}

@font-face {
  font-family: "NanumGothicBold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/NanumGothicBold.ttf") format("truetype"),
    url("../fonts/NanumGothicBold.otf") format("opentype");
}

@font-face {
  font-family: "NanumGothicExtraBold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/NanumGothicExtraBold.ttf") format("truetype"),
    url("../fonts/NanumGothicExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "NanumGothicLight";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/NanumGothicLight.ttf") format("truetype"),
    url("../fonts/NanumGothicLight.otf") format("opentype");
}
