.souvenir-img-size {
  margin-top: 25px;
  width: 700px;
}

@media (max-width: 767.98px) {
  .souvenir-img-size {
    width: 300px;
    margin-top: 20px;
  }
}
