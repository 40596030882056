.login-container {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-container__items {
  width: 400px;
}

.login-container__btn {
  width: 400px;
  margin-top: 5px;
}

.login-container__img {
  width: 300px;
  height: 150px;
  margin-bottom: 48px;
}

.login-container__p {
  font-size: 2rem;
}

.login-container__bottom-border {
  width: 400px;
}

@media (max-width: 575.98px) {
  .login-container__items,
  .login-container__btn,
  .login-container__bottom-border {
    width: 350px;
  }

  .login-container__img {
    width: 200px;
    height: 100px;
  }
}
