.history-padding {
  padding-left: 150px;
}

@media (max-width: 767.98px) {
  .history-padding {
    padding-left: 0;
    font-size: 14px;
  }
}
