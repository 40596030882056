.my-container {
  display: grid;
  margin: 10px 145.5px 0 145.5px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-template-areas:
    "a a a a"
    "d c c c"
    "d c c c"
    "d c c c"
    "d c c c";
}

.item1 {
  grid-area: a;
}

.item2 {
  grid-area: b;
}

.item3 {
  grid-area: c;
}

.item4 {
  grid-area: d;
}

@media (max-width: 1104.98px) {
  .sidebar-display {
    display: none;
  }

  .my-container {
    grid-template-areas:
      "a a a a"
      "c c c c"
      "c c c c"
      "c c c c"
      "c c c c";

    margin: auto;
  }
  .item3 {
    margin-top: 24px;
  }
}
