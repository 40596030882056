.individual-com {
  color: #00b1bb;
  font-size: 48px;
  font-family: var(--h2-font-family);
}

.btn-size {
  width: 100%;
  border: none;
  color: white;
  font-family: var(--h2-font-family);
}

.btn-add-individual {
  background-color: #ea6000;
}

.btn-inquiry {
  background-color: #00b1bb;
}

.btn-home {
  background-color: #ea6000;
}

#copy-icon {
  cursor: pointer;
}

#copy-icon:hover {
  color: rgb(69, 69, 255);
}

#copy-info {
  display: block;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}

@media (max-width: 575.98px) {
  .individual-com {
    font-size: 32px;
  }
}
