.info-image-size {
  width: 900px;
}

@media (max-width: 991.98px) {
  .info-image-size {
    width: 700px;
  }
}

@media (max-width: 767.98px) {
  .info-image-size {
    width: 500px;
  }
}

@media (max-width: 566.98px) {
  .info-image-size {
    width: 300px;
  }
}
