.admin-sidebar {
  width: 250px;
  height: 700px;
  padding: 1rem;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  color: #fff;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1));
}

.admin-dashboard {
  display: flex;
}

.admin-main-word {
  color: white;
  font-weight: bold;
}

.admin-main-total-count {
  background: #36bdef;
}

.admin-main-individual-count {
  background: #ea6000;
}

.admin-main-group-count {
  background: #00b1bb;
}

.admin-main-limit-count {
  background: #002063;
}

.admin-main-attend {
  background: #4c7f37;
}

.admin-main-competition {
  background: #58585d;
}

.admin-mobile-navbar {
  display: none;
}

.admin-table {
  white-space: nowrap;
  text-align: center;
}

.admin-setting-container {
  margin-left: 24px !important;
}

.admin-setting-text-size {
  font-size: 16px;
}

@media (max-width: 575.98px) {
  .admin-sidebar {
    display: none;
  }
  .admin-mobile-navbar {
    background-color: #212529 !important;
    text-align: center;
    display: block;
  }

  .admin-setting-container {
    margin-left: 5px !important;
  }

  .admin-setting-text-size {
    font-size: 14px;
  }
}
