.essential {
  color: red;
}
/* 
.input-max-width {
  max-width: 500px;
}

.post-input-max-width {
  max-width: 403px;
}
*/

.input-btn-width {
  width: 100%;
}

.container-margin-left {
  margin-left: 12px;
}

.warning-text {
  color: red;
  margin: 0;
  font-size: 10px;
}

.group-count {
  font-family: "NanumGothic";
  margin-bottom: 0;
}

.none-display {
  display: none;
}

@media (max-width: 991.98px) {
  .sidebar-display {
    display: none;
  }
  .form-ms {
    margin-left: 0;
  }
}
