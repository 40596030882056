.upload-width-size {
  width: 30% !important;
  text-align: left;
}

@media (max-width: 575.98px) {
  .upload-width-size {
    width: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .upload-width-size {
    width: 40% !important;
  }
}
