.detail-last-competition-title {
  font-size: 32px;
}

.detail-last-competition-image {
  width: 500px;
}

p:has(img) {
  text-align: center;
}

@media (max-width: 767.98px) {
  .detail-last-competition-title {
    font-size: 17px;
  }

  .detail-last-competition-image {
    width: 300px;
  }

  .detail-last-competition-created {
    font-size: 12px;
  }

  .detail-last-competition-content {
    font-size: 80%;
  }
}
