.limit-attend-font-size {
  font-size: 48px;
}

.limit-icon-size {
  font-size: 100px;
}

.limit-margin {
  margin-bottom: 30vh;
}

@media (max-width: 575.98px) {
  .limit-attend-font-size {
    font-size: 24px;
  }

  .limit-icon-size {
    font-size: 50px;
  }
}
