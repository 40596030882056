.kakao-link {
  position: fixed;
  bottom: 90px;
  right: 100px;
  z-index: 900;
  border: none;
  border-radius: 10px;
  background: #e5e050;
  color: white;
  padding: 6px;
  font-weight: bold;
  box-shadow: 0px 5px 15px gray;
  cursor: pointer;
  text-align: center;
}

.kakao-img {
  width: 30px;
}

.kakao-text {
  margin: 0;
  font-size: 10px;
}

@media (max-width: 575.98px) {
  .kakao-link {
    bottom: 20px;
    right: 20px;
    padding: 7px;
    font-size: 12px;
  }
}
