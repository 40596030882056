.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 15px;
}

.card-position {
  width: 300px;
  margin: 15px;
  cursor: pointer;
}

.last-competition-write-btn {
  text-align: right !important;
}

.dropdown-custom {
  cursor: pointer;
}

.img-size {
  width: 298px !important;
  height: 417px !important;
}

.last-competition-title {
  font-size: 14px;
  text-align: center;
}

.last-competition-space {
  margin-bottom: 60vh;
}

@media (max-width: 767.98px) {
  .last-competition-title {
    font-size: 12px;
  }
}
