:root {
  --h1-font-family: "NanumGothicExtraBold";
  --h1-font-color: #36bdef;
  --h2-font-family: "NanumGothicBold";
  --main-font-family: "NanumGothic";
  --light-font-family: "NanumGothicLight";
}

a {
  text-decoration: none !important;
  color: black !important;
}

.bread-crumb {
  font-family: var(--main-font-family), "Apple SD Gothic Neo", Sans-serif !important;
}

.header-font {
  font-family: var(--h2-font-family), "Apple SD Gothic Neo", Sans-serif !important;
}

.footer-font {
  font-family: var(--light-font-family), "Apple SD Gothic Neo", Sans-serif;
}

.main-font {
  font-family: var(--main-font-family), "Apple SD Gothic Neo", Sans-serif !important;
}

.bold-font {
  font-family: var(--h2-font-family), "Apple SD Gothic Neo", Sans-serif !important;
}

.extar-bold-font {
  font-family: var(--h1-font-family), "Apple SD Gothic Neo", Sans-serif;
}
