.test {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.test-none {
  display: none;
}

.pop-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  text-align: center;
}
.pop-img {
  display: block;
  width: 400px;
  height: 600px;
}

.today-button {
  width: 200px !important;
}

.close-button {
  width: 200px !important;
}

#background-color-one {
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 575.98px) {
  .pop-img {
    width: 80vw;
    height: 80vh;
    margin: auto;
  }

  .today-button {
    width: 40vw !important;
  }

  .close-button {
    width: 40vw !important;
  }
}
