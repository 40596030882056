.footer-backgroud-color {
  background-color: #76847d;
  margin-top: 50px;
}

.footer-container {
  display: flex;
  padding: 5px 50px 5px 50px;
  flex-wrap: wrap;
}

.footer-logo {
  width: 120px;
  height: 55px;
}

.footer-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: white;
}
.footer-copyright {
  padding: 5px 50px 5px 50px;
  color: white;
  margin: 0;
}

@media (max-width: 575.98px) {
  .footer-container,
  .footer-copyright {
    padding: 5px 20px 5px 20px;
  }

  .footer-logo {
    padding-bottom: 5px;
  }

  .footer-vertical-bar {
    display: none;
  }
}
